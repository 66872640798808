import { FC, useEffect, useId, useMemo, useState } from "react"
import { useOutletContext } from "react-router-dom"

import type { LoggedInUser } from "../auth/useGetLoggedInUser"

import useAppConfig from "../hooks/useConfig"
import useDocumentTitle from "../hooks/useDocumentTitle"

import { downloadFile } from "../utils/download"
import useFetch from "../hooks/useFetch"
import {
  Endorsement,
  getEndorsementPresignedUrl,
  listEndorsements,
  listEndorsementsByUMR,
} from "../api/endorsements"
import * as Sentry from "@sentry/react"
import ErrorMessage from "../components/ErrorMessage"
import { UnauthorisedError } from "../api/utils"
import Loading from "../components/Loading"

import TreeView, { INode, flattenTree } from "react-accessible-treeview"
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import {
  DocumentIcon,
  FolderOpenIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline"

import classes from "../styles/index.module.css"
import useRedirectToLogin from "../hooks/useRedirectToLogin"
import SearchBar from "../components/SearchBar"

const PAGE_NAME = "Endorsements"

// Given a filename, make it fully qualified by prepending the names of all its
// parent folders
const getFullyQualifiedFilename = (
  element: INode,
  treeNodes: INode[],
): string => {
  const parent = treeNodes.find((node) => node.id === element.parent)
  // If we've reached the root node, or we're immediately inside the root
  // folder, stop recursing. We skip the root folder because it's a 'fake'
  // folder that is just named for the syndicate
  if (parent === undefined || parent.parent === null) {
    return element.name
  }
  // Otherwise we prepend the parent's name and recurse upwards
  else {
    return `${getFullyQualifiedFilename(parent, treeNodes)}/${element.name}`
  }
}

interface TreeNode {
  name: string
  children: TreeNode[]
  metadata: { type: "node" } | { type: "leaf"; isZip?: boolean }
}

// Recursively transform an `Endorsement` from the API into a `TreeNode` for the
// `react-accessible-treeview` library
const transformDataForTreeview = (doc: Endorsement): TreeNode => ({
  name: doc.name,
  metadata:
    doc.children === null
      ? { type: "leaf", isZip: doc.isZip }
      : { type: "node" },
  children: (doc.children ?? []).map(transformDataForTreeview),
})

const EndorsementsScreen: FC = () => {
  useDocumentTitle(PAGE_NAME)

  const [umr, setUmr] = useState<string | null>(null)
  const { API_URL } = useAppConfig()
  const loggedInUser = useOutletContext<LoggedInUser>()

  const fetcher = umr ? listEndorsementsByUMR : listEndorsements
  const filesReq = useFetch(
    fetcher,
    API_URL,
    loggedInUser.accessToken,
    umr || undefined,
  )

  const treeviewData = useMemo(
    () =>
      filesReq.type === "Success"
        ? flattenTree(transformDataForTreeview(filesReq.data))
        : [],
    [filesReq],
  )

  const headingId = useId()
  const redirectToLogin = useRedirectToLogin()

  const handleSearch = (umr: string) => {
    setUmr(umr.trim() ? umr : null)
  }

  const handleReset = () => {
    setUmr(null)
  }

  useEffect(() => {
    if (umr === null) {
      setUmr("")
    }
  }, [umr, API_URL, loggedInUser.accessToken])

  return (
    <div className={classes.main}>
      <h1 id={headingId}>{PAGE_NAME}</h1>

      <p className={classes.copy}>
        You are viewing endorsement files related to bound contracts for{" "}
        {loggedInUser.syndicate}:
      </p>

      <SearchBar onSearch={handleSearch} onReset={handleReset} />

      {filesReq.type === "Success" ? (
        <TreeView
          data={treeviewData}
          className={classes.tree}
          aria-labelledby={headingId}
          nodeRenderer={({ getNodeProps, isBranch, isExpanded, element }) => {
            type State = "file" | "folder" | "empty-folder"
            const state: State =
              element.metadata?.type === "leaf"
                ? "file"
                : isBranch
                ? "folder"
                : "empty-folder"

            switch (state) {
              case "file": {
                return (
                  <div {...getNodeProps()} className={classes.file}>
                    <DocumentIcon className={classes.icon} />
                    <span className="sr-only">File: </span>
                    <span>{element.name}</span>

                    <div role="presentation" className={classes.spacer} />

                    <button
                      title={`Download file ${element.name}`}
                      onClick={async () => {
                        try {
                          const fullyQualifiedFilename =
                            getFullyQualifiedFilename(element, treeviewData)

                          const presignedUrl = await getEndorsementPresignedUrl(
                            API_URL,
                            loggedInUser.accessToken,
                            fullyQualifiedFilename,
                          )

                          await downloadFile(
                            presignedUrl.url,
                            element.name,
                            presignedUrl.contentType,
                          )
                        } catch (e) {
                          Sentry.captureException(e)
                          if (e instanceof UnauthorisedError) {
                            // Handle 401 error and navigate to login page
                            redirectToLogin()
                          }
                        }
                      }}
                    >
                      <span className={classes.meta}>
                        Download
                        <span className="sr-only">file {element.name}</span>
                      </span>
                      <DocumentArrowDownIcon className={classes.icon} />
                    </button>
                  </div>
                )
              }
              case "folder": {
                return (
                  <div {...getNodeProps()} className={classes.folder}>
                    {isExpanded ? (
                      <ChevronDownIcon className={classes.icon} />
                    ) : (
                      <ChevronRightIcon className={classes.icon} />
                    )}
                    <span className="sr-only">Folder: </span>
                    <span>{element.name}</span>
                  </div>
                )
              }
              case "empty-folder": {
                return (
                  <div {...getNodeProps()} className={classes["empty-folder"]}>
                    <FolderOpenIcon className={classes.icon} />
                    <span className="sr-only">Folder: </span>
                    <span>{element.name}</span>
                    <span className={classes.meta}>(empty folder)</span>
                  </div>
                )
              }
            }
          }}
        />
      ) : filesReq.type === "Failure" ? (
        <ErrorMessage
          message="Failed to load documents"
          error={filesReq.error}
          dataTestId="fetch-documents-error"
        />
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default EndorsementsScreen
